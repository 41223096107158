
const routeConfig = [
    { path: "/", title: "Inicio" },
    { path: "/Teams", title: "Equipos" },
    { path: "/Superbowls", title: "Super Bowls" },
    { path: "/Glossary", title: "Glosario" },
    { path: "/Formations", title: "Formaciones" },
    // Add more routes as needed
];

export default routeConfig;
